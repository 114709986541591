.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: transparent;
  /*min-height: 97vh;*/
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(37, 37, 37);
}

.App-header-home {
  background-color: transparent;
  /*min-height: 120px;*/
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /*text-transform: uppercase;*/
}

.App-header-home .Page-subtitle-top{
  text-transform: none;
  color:black;
}

.App-header-home .Page-title-top{
  text-transform: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-title {
  position: absolute;
  top: 20px;
}

.Page-title {
  margin-bottom: 20px;
  padding: 0 30px;
  font-size: 27px;
  margin-top: 20px;
}

.Page-title-top {
  top: 120px;
  padding: 0 30px;
  font-size: 30px;
  margin-bottom: 20px;
}

.App-header .Page-title-top {
  position: absolute;
}

.Page-subtitle-top {
  top: 200px;
  font-size: 20px;
  padding: 0 30px;
  margin: 10px auto;
  max-width: 1200px;
}

.Page-subtitle-orange {
  color: orange;
  font-size: 20px;
}

.Page-subtitle-green {
  color: green;
  font-size: 20px;
}

.Page-description-top {
  top: 250px;
  font-size: 20px;
  padding: 0 30px;
  margin: 10px 0;
}

.Error-message {
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
}

.Success-message {
  color: green;
  font-size: 20px;
  margin-bottom: 20px;
}

.Form-control {
  height: auto;
  text-align: left;
}

.Form-control.Register{
  width: 90%;
  max-width: 520px;
  font-size: 18px;
}

.Form-control.AddClass{
  width: 90%;
  font-size: 18px;
}

.Form-control.AddClass .MuiGrid-item{
  text-align: center;
  padding: 0px;
  margin: 8px;
}

.Form-control.AddClass button{
  width: 98%;
  margin: 5px;
}

.Form-control.SchoolData{
  min-height: 240px;
}

.Form-control, .Form-control label {
  padding: 10px;
}

.Form-control label {
  width: 90px;
}

.Form-label {
  text-align: left;
  padding-left: 10px;
  width: 232px;
  font-size: 20px;
}

.Form-title-label.Register{
  margin-top: 20px;
  width: 90%!important;
  max-width: 520px;
  text-align: left;
  padding-left: 10px;
  width: 232px;
  font-size: 20px;
}

.Form-label.Register{
  /*margin-top: 20px;*/
  width: 90%!important;
  max-width: 520px;
  text-align: left;
  /*padding-left: 10px;*/
  width: 232px;
  font-size: 18px;
}

.Form-link {
  text-align: center;
  padding-left: 10px;
  width: 232px;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Form-title {
  text-align: left;
  padding-left: 10px;
  width: 232px;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.Form-control input {
  font-size: 1rem;
  padding: 10px 10px;
  width: 260px;
}

.Form-control.Register input {
  width: 100%;
}

.Form-control.Register .MuiFormControl-root {
  font-size: 1rem;
  padding: 10px 0;
  width: 100%;
}

.Form-control.Register select{
  padding-top: 10px;
  padding-bottom: 10px;
}


.Form-control .MuiInputBase-multiline {
  font-size: 1rem;
  width: 224px;
  height: 100px;
  display: block;
}

.Form-control .Form-control {
  width: 90%;
}

.Form-control.Register .Form-control.Register {
  width: 90%;
  max-width: 520px;
  font-size: 18px;
  padding: 10px 30px;
}

.Form-link a {
  color: rgb(37, 37, 37);
}

button.submit, button[type=submit]{
  font-size: 20px;
  padding: 10px;
  margin: 0;
  width: 280px;
}

.EditAddress{
  font-size: 16px!important;
  padding: 10px!important;
  margin: 0!important;
}

.Form-control.Register button.submit, .Form-control.Register button[type=submit]{
  width: 100%;
} 

button[name="login-button"]{
  color: white;
  background-color: blue;
}

button[name="register-button"]{
  color: white;
  background-color: orange;
}

.Topbar {
  position: absolute;
  border: 1px solid rgb(37, 37, 37);
  height: 40px;
  width: 100%;
  top: 0px;
}

.WelcomeBar {
  display: flex;
  position: absolute;
  background-color: #fff!important;
  color: #444!important;
  height: 47px;
  width: 100%;
  top: 0px;
  box-shadow: none!important;
}

.WelcomeBar .MuiToolbar-root{
  height: 40px;
  min-height: 40px;
  margin-right: auto;
  margin-left: auto;
}

.Menu-icon {
  float: right;
  margin-right: 10px;
}

.Menu-icon svg {
  margin-top: 6px;
}

.Profile {
  width: 100px;
}

.Logout {
  width: 100px;
}

.WelcomeContainer {
  width: 100%;
  padding: 0 20px;
}

.WelcomeMenu{
  font-size: 16px;
  display: inline-table;
  width: 100%;
}

.WelcomeMenu .AccountMenuButton{
  text-align: right;
}

.WelcomeMessage {
  width: 300px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 2.2;
}

.MuiButton-text{
  padding: 0px!important;
}

.MenuButton span{
  text-transform: capitalize;
  font-size: 16px;
}

/*.Progress {
  position: absolute;
  top: 45vh;
  left: calc(50% - 20px);
}*/

.separator {
  margin-top: 60px;
}

.Mui-error {
  margin-left: 0px;
}

.Checkbox {
   width: 224px;
   margin-right: 0px!important;
   margin-bottom: 20px;
}

.Checkbox.Register{
  width: 100%;
  margin-right: 0px!important;
  margin-bottom: 0px;
  height: 10px;
}

.Checkbox.ConfirmParticipation{
  width: 90%;
  margin-right: 0px!important;
  margin-bottom: 0px;
  height: 10px;
}

.Checkbox.Checkbox.ConfirmParticipation .label-desc{
  padding-right: 0px!important;
  padding-left: 0px!important;
}

.Checkbox label {
  font-size: 12px;
}

.Checkbox .MuiFormControlLabel-label {
  text-align: left;
}

a {
  text-decoration: none;
}

.Page-button-group {
  width: 100%!important;
  padding: 20px;
  margin: 0!important;
}

.Button-100 {
  width: 100%;
  padding: 14px 22px;
  font-size: 14px;
  height: 100%;
}

.Button-90 {
  width: 90%;
  padding: 14px 22px;
  font-size: 14px;
  height: 100%;
}

.Point-box {
  background-color: rgb(206, 87, 206);
  padding: 14px 22px;
  font-size: 25px;
  color: white;
}

.Page-table {
  width: 100%!important;
  padding: 20px;
  margin: 0;
}

.Button-bottom{
   width: 100%;
    position: absolute;
    top: 78vh;
}

.Button-bottom-single{
  width: 100%;
   position: absolute;
   top: 86vh;
}

.Select-shop{
  text-align: left;
}

.Home-description{
    font-size: 20px;
    padding: 20px 30px;
    color: #189c37;
}

.Home-description a{
  color: #189c37;
}

.Home-description-blu a{
  color: #1976d2;
}

.Home-description-black a{
  color: #000000;
}

.Home-description-blu{
  font-size: 20px;
  padding: 20px 30px;
  color: #1976d2;
}

.Home-description-black{
  font-size: 20px;
  padding: 20px 30px;
  color: #000000;
}

@media screen and (max-width: 529px) {
  .Logo-Bar{
    display: block;
    margin-top: 20px;
  }
}

@media screen and (min-width: 530px) {
  .Logo-Bar{
    display: flex;
    margin-top: 20px;
  }
  .Logo-Menu-Bar{
    display: flex;
    margin-top: 3px;
  }
}

.Logo-Box{
  margin: auto 10px;
}

.Logo-Menu-Box{
  margin: auto 10px;
}

.Orange{
  color: orange;
}

.Green{
  color: green;
}

.BoxHidden{
  display: none;
}

ul{
  padding-inline-start: 0px!important;
}

ul li{
  display: block;
}

.Box-fullpage{
  height: calc(100vh - 114px);
  max-width: 99.7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Form-link.PasswordReset{
  text-decoration: underline;
}

.Form-control.Register.Half, .Form-control.AddClass.Half{
  padding: 10px 0;
}

.TableContainer{
  max-width: 1200px;
}

.DatiClasse{
  max-width: 1200px;
  font-size: 20px;
  /*padding: 20px 20px 15px 20px;*/
  padding: 0;
  margin: 10px auto;
  text-align: left;
  background-color: rgb(227, 238, 227);
}

.TopButton{
  max-width: 1200px;
  padding: 0;
  margin: 10px auto;
  text-align: left;
  width: 100%;
}

.TabellaClassi tbody td{
  font-size: 14px;
}

.TabellaClassi tfoot td{
  font-size: 16px;
}

.TabellaClassi tfoot tr.first td{
  color: black;
}

.TabellaClassi tfoot tr.second td{
  color: rgb(7, 59, 143);
}

.TabellaClassi tfoot tr.third td{
  color: rgb(6, 167, 68);
}


.HeadTabellaClassi{
  background-color:gainsboro ;
}

.HeadTabellaClassi th{
  /*text-align: center;*/
  text-transform: uppercase;
}

.HeadTabellaClassi div.MuiSelect-select{
  text-transform: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.BodyTabellaClassi td td{
  border: none;
  padding: 0;
}

.TabellaClassi tfoot td{
  padding: 16px 16px 8px 16px;
}

.BodyTabellaClassi{
  background-color: rgb(171, 235, 171);
}

.BodyTabellaClassi td, .BodyTabellaClassi th, .TabellaClassi tfoot td{
  border: none;
}

.BodyTabellaClassi > tr:nth-child(even){
  background-color: rgb(208, 231, 208);
}

.TabellaSquadre tbody td{
  font-size: 14px;
}

.TabellaSquadre tfoot td{
  font-size: 16px;
}

.TabellaSquadre tfoot tr.first td{
  color: black;
}

.TabellaSquadre tfoot tr.second td{
  color: rgb(7, 59, 143);
}

.TabellaSquadre tfoot tr.third td{
  color: rgb(6, 167, 68);
}


.HeadTabellaSquadre{
  background-color:gainsboro ;
}

.HeadTabellaSquadre th{
  text-align: center;
  text-transform: uppercase;
}

.BodyTabellaSquadre td td{
  border: none;
  padding: 0;
}

.TabellaSquadre tfoot td{
  padding: 16px 16px 8px 16px;
}

.BodyTabellaSquadre{
  background-color: rgb(171, 235, 171);
}

.BodyTabellaSquadre td, .BodyTabellaSquadre th, .TabellaSquadre tfoot td{
  /*border: none;*/
  border-left: 1px solid;
}

.BodyTabellaSquadre > tr:nth-child(even){
  background-color: rgb(208, 231, 208);
}

.qty-secondary-field input[type=number]{
  height : 10px;
  width: 60px;
}

.WelcomeMenu .Selected{
  color: orange;
}

.WelcomeMenu button{
  color: white;
}

.AlertSpedizione{
  font-size: 18px!important;
  border: 1px red;
  border-style: double;
  padding: 20px;
  margin: 30px 0px;
}

.AlertClassiIscritte{
  font-size: 18px!important;
  padding: 20px;
  margin: 30px 0px;
}

.AlertSpedizione .AlertTitle{
  font-size: 20px;
}

.AlertSpedizione .Success{
  border-color: green;
}

.Hidden{
  display: none!important;
}

.Divider{
  height: 8px;
  width: 100%;
  margin: 10px 10px 20px 10px!important;
}

@media screen and (max-width: 599px) {
  .WelcomeUser{
    display: none;
  }
  .WelcomeBar .MuiToolbar-root{
    min-width: 400px;
  }
}

@media screen and (min-width: 600px) {
  .WelcomeUser{
    margin-right: 10px;
  }
  .WelcomeBar .MuiToolbar-root{
    min-width: 62%;
  }
}

.School-Data td{
  vertical-align: top;
}

ul.Project-description li{
  display: list-item;
  text-align: left;
}

.Modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 20px;
}

.Modal h2, .Modal p{
  margin-bottom: 10px;
}

.Modal button{
  margin-right: 20px;
}

.Modal .RegisterTeamButtonContainer button{
  margin-right: 0;
}

@media screen and (min-height: 768px) {
  .adaptive{
    height: calc(100vh - 595px);
  }
  .adaptive-home{
    height: calc(100vh - 681px);
  }
}

@media screen and (max-width: 767px) {

  .TabellaClassi{
    min-width: 0px!important;
  }

  .TabellaClassi, .TabellaClassi thead, .TabellaClassitbody, .TabellaClassi th, .TabellaClassi td, .TabellaClassi th{
    display: block;
  }

  .TabellaClassi thead tr{
    display: none;
  }  

  .TabellaClassi tfoot{
    display: inline-flex;
  }

  .TabellaClassi .MobileHide{
    display: none;
  }

  .TabellaClassi > tbody > tr > td{
    padding-left: 45%;
    position: relative;
    text-align: left;
    min-height: 20px;
  }

  .TabellaClassi tr td:before{
    font-weight: bold;
    position: absolute;
    text-align: left;
    width: 35%;
    left:6px;
    padding-right: 10px;
    content: attr(data-title);
    white-space: nowrap;
  }

  .TabellaSquadre{
    min-width: 0px!important;
  }

  .TabellaSquadre, .TabellaSquadre thead, .TabellaSquadretbody, .TabellaSquadre th, .TabellaSquadre td, .TabellaSquadre th{
    display: block;
  }

  .TabellaSquadre thead tr{
    display: none;
  }  

  .TabellaSquadre tfoot{
    display: inline-flex;
  }

  .TabellaSquadre .MobileHide{
    display: none;
  }

  .TabellaSquadre > tbody > tr > td{
    padding-left: 45%;
    position: relative;
    text-align: left;
    min-height: 20px;
  }

  .TabellaSquadre tr td:before{
    font-weight: bold;
    position: absolute;
    text-align: left;
    width: 35%;
    left:6px;
    padding-right: 10px;
    content: attr(data-title);
    white-space: nowrap;
  }

}

.Full-text{
  margin: 0 100px;
  text-align: left;
  font-size: 20px;
}


@media screen and (max-width: 619px) {
  .Footer{
    display: flex;
      position: absolute;
      background-color: #00b40f!important;
      width: 100%;
      height: 70px;
      color: white;
  }
}

@media screen and (min-width: 620px) {
  .Footer{
    display: flex;
      position: absolute;
      background-color: #00b40f!important;
      width: 100%;
      height: 50px;
      color: white;
  }
}

.Footer div{
  padding: 14px;
}

.Footer a:visited{
  color: white;
}

.Footer a:hover{
  text-decoration: underline;
}

.Checkbox.Register .label-desc{
  padding: 0;
}

.ActionButton{
  padding: 10px!important;
}

.FileInputTeacher{
  font-size: 20px;
  padding: 10px;
  margin: 0;
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  display: inline-flex;
  width: 96%!important;
}

.FileInputTeacher.disabled, .AssociateStudent:disabled, .FileInputStudent.disabled{
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
    pointer-events: none;
    cursor: default;
}

.FileInputStudent, .AssociateStudent{
  font-size: 16px;
  padding: 10px;
  margin: 0;
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  display: inline-flex;
  width: 94%!important;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-width: 220px!important;
}

.FileInputTeacher:hover, .FileInputStudent:hover{
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #1565c0;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

input[type="file"]{
  display: none;
}

.App-subheader-home{
  width: 100%;
}

.App-subheader-container-home{
    width: auto;
    max-width: 1200px;
    margin: 16px auto; 
}

.App-subheader-home .header-menu{
    font-family: Montserrat;
    font-weight: 400;
    color: #fff;
    margin-top: 16px;
}

.App-subheader-home .header-menu .menu-item{
    position: relative;
    float: left;
    padding: 0 14px;
    z-index: 20;
}

.App-subheader-home .header-menu .menu-item a{
    display: block;
    padding: 6px 0;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none!important;
    color: #444;
    font-weight: 500;
}

.App-subheader-home .header-menu .menu-item a:visited{
  text-decoration: none!important;
}

.App-subheader-home .header-menu .menu-item.active{
  background-color: #48a7d4;
}

.pull-right{
  float: right;
}

#user-menu-button{
  margin-right: 10px;
  text-transform: none;
  color: #444;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  text-decoration: underline;
}
#logout-button{
  text-transform: none;
  min-width: 50px;
  color: #444;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  text-decoration: underline;
}
#home-button, #mxt-signup-button, #tokalon-website-button, #previous-edition-button, #facebook-button, #instagram-button, #youtube-button{
  min-width: 30px;
  color: #1976d2!important;
  font-family: Montserrat;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
}
#home-button{
  margin-right: 10px;
}
#tokalon-website-button{
  margin-left: 10px;
}
#mxt-signup-button, #previous-edition-button{
  margin-left: 10px;
  margin-right: 10px;
}
#youtube-button{
  margin-right: 30px;
}
#user-menu-button:hover, #logout-button:hover, #home-button:hover, #facebook-button:hover, #instagram-button:hover, #youtube-button:hover{
  color: #444;
}

.HomeMenuColumn{
  text-align: left;
}

.RegisterTeam, .DeleteTeam{
  margin-bottom: 16px!important;
  min-width: 100px!important;
}

.RegisterTeam:last-child, .DeleteTeam:last-child {
  margin-bottom: 0!important;
}

#cancel-button{
  background-color: #777;
}

.RegisterTeamButtonContainer div, .DeleteTeamButtonContainer div{
  text-align: center;
}

.WelcomeUser{
  text-decoration: underline;
}

.LogoMenuColumn{
  width: 100px;
}

.AdviceBox{
  font-size: 14px;
  color: #000;
}

.AdviceBox table{
  border-color: #1976d2;
  border-collapse:collapse;
  width: 1040px;
  padding: 5px;
}